import { NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'

const MainNavigation = ({ config, onNavItemClick }) => {
  const swiperNavigation = useRef()
  const normalNavigation = useRef()
  const [showSwiperNavigation, setShowSwiperNavigation] = useState(false)

  const updateNavigationVisibility = () => {
    if (normalNavigation.current.clientWidth !== normalNavigation.current.scrollWidth) {
      setShowSwiperNavigation(true)
    } else {
      setShowSwiperNavigation(false)
    }
  }

  const resizeObserver = useMemo(() => {
    return new window.ResizeObserver(entries => {
      updateNavigationVisibility()
    })
  }, [])

  useEffect(() => {
    resizeObserver.observe(normalNavigation.current)
  }, [resizeObserver])

  return (
    <nav className="text-center overflow-hidden">
      {/* TODO: Dynamic headline */}
      <div className="max-w-main-navigation-headline mx-auto px-8 pt-4 ">
        <h2 className="text-headline">
          Explore all seasons <br /> of The Cube
        </h2>
      </div>

      <div className="mt-12 xs:mt-20">
        <div ref={swiperNavigation}>
          <Swiper
            centeredSlides
            observeParents
            observer
            watchOverflow
            className={classNames({
              'invisible h-0': !showSwiperNavigation,
            })}
            slidesPerView="auto"
          >
            {config.navigationItem.map(item => {
              return (
                <SwiperSlide key={item.slug.current} className="w-auto px-6">
                  <div className="w-60">
                    <NavLink to={item.slug.current} onClick={onNavItemClick}>
                      <figure>
                        <img alt={item.label} height="200" src={item.image.secure_url} width="200" />
                        <figcaption className="mt-4 inline-block whitespace-pre-line">
                          {item.cube.navigationFields.navigationLabel}
                        </figcaption>
                      </figure>
                    </NavLink>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>

        <div
          ref={normalNavigation}
          className={classNames({
            'flex justify-center': true,
            'invisible h-0': showSwiperNavigation,
          })}
        >
          {config.navigationItem.map(item => {
            return (
              <div key={item.slug.current} className="px-6 flex-shrink-0">
                <NavLink to={item.slug.current} onClick={onNavItemClick}>
                  <figure className="w-60">
                    <img alt={item.label} className="mx-auto" height="200" src={item.image.secure_url} width="200" />
                    <figcaption className="mt-4 inline-block whitespace-pre-line text-center">
                      {item.cube.navigationFields.navigationLabel}
                    </figcaption>
                  </figure>
                </NavLink>
              </div>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default MainNavigation
