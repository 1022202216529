import { GraphQLClient, gql } from 'graphql-request'

const GRAPHQL_ENDPOINT = 'https://n6zzphlb.api.sanity.io/v1/graphql/production/default'

const client = new GraphQLClient(GRAPHQL_ENDPOINT, { headers: {} })

const configQuery = gql`
  fragment CommonProperties on CommonContentFields {
    title
    audioTitleFile {
      secure_url
    }
  }

  fragment CommonNavigationFields on CommonNavigationFields {
    navigationLabel
  }

  fragment CommonMetaFields on CommonMetaFields {
    metaTitle
    metaDescription
    ogImage {
      secure_url
      width
      height
      resource_type
      format
    }
    ogImageAlt
    ogTwitterHandle
  }

  fragment CommonThemeFields on CommonThemeFields {
    interfaceBackgroundColor {
      hex
    }
    interfaceTextColor {
      hex
    }
    contentTextColor {
      hex
    }
    contentDifferenceEffect
  }

  fragment ShaderPassFields on ShaderPass {
    _id
    title
    uniforms {
      ... on UniformFloat {
        name
        type
        dynamicValue
        params
        body {
          code
        }
        defaultValue
      }
      ... on UniformColor {
        name
        type
        dynamicValue
        params
        body {
          code
        }
        defaultColorValue: defaultValue {
          rgb {
            r
            g
            b
          }
        }
      }
    }
    fragmentShader {
      code
    }
  }

  fragment CommonNavigationPageFields on Page {
    slug {
      current
    }
    image {
      secure_url
    }
    cube {
      __typename
      ... on CubeV1 {
        navigationFields {
          ...CommonNavigationFields
        }
      }
      ... on CubeV2 {
        navigationFields {
          ...CommonNavigationFields
        }
      }
      ... on CubeV3 {
        navigationFields {
          ...CommonNavigationFields
        }
      }
    }
  }

  fragment CommonNavigationExternalLinkFields on ExternalLinkNavigationItem {
    label
    url
  }

  query Configuration {
    allPage {
      slug {
        current
      }
      cube {
        __typename
        ... on CubeV1 {
          metaFields {
            ...CommonMetaFields
          }
        }
        ... on CubeV2 {
          metaFields {
            ...CommonMetaFields
          }
          themeFields {
            ...CommonThemeFields
          }
          sphereShaders {
            ...ShaderPassFields
          }
          introBackground {
            __typename
            ... on IntroBackgroundImage {
              image {
                secure_url
                width
                height
              }
            }
            ... on IntroBackgroundVideo {
              video {
                secure_url
                width
                height
                format
                type
              }
              poster {
                secure_url
              }
            }
          }
          config
          backgroundVideoTexture {
            secure_url
          }
          outsideTextureUrl {
            secure_url
          }
          soundtrack {
            secure_url
          }
          cubeEnterSound {
            secure_url
          }
          cubeEnterSoundVolume
          introTitle
          introText
          episodes {
            ... on OralHistory {
              _type
              commonProperties {
                title
                noCameraSnapping
                audioTitleFile {
                  secure_url
                }
              }
              faces {
                ... on OralHistoryFace {
                  _type
                  records {
                    title
                    subline
                    audioFile {
                      secure_url
                    }
                    texture {
                      secure_url
                      resource_type
                    }
                  }
                }
                ... on OralHistoryEmptyFace {
                  _type
                  useEqualizer
                }
              }
            }
            ... on Podcast {
              _type
              commonProperties {
                ...CommonProperties
              }
              audioFile {
                secure_url
              }
              faces {
                sections {
                  time
                  texture {
                    secure_url
                    resource_type
                  }
                }
              }
            }
            ... on ImmersiveVideo {
              _type
              commonProperties {
                ...CommonProperties
              }
              initialSide
              texture {
                secure_url
              }
            }
            ... on VideoWall {
              _type
              commonProperties {
                ...CommonProperties
              }
              faces {
                texture {
                  secure_url
                }
              }
            }
            ... on EmptyFace {
              _type
              title
            }
          }
          limitVerticalCubeRotation
        }
        ... on CubeV3 {
          new
          metaFields {
            ...CommonMetaFields
          }
        }
      }
    }

    MainNavigation(id: "mainNavigation") {
      navigationItem {
        ... on Page {
          ...CommonNavigationPageFields
        }
        ... on ExternalLinkNavigationItem {
          ...CommonNavigationExternalLinkFields
        }
      }
    }

    MetaNavigation(id: "metaNavigation") {
      navigationItem {
        ... on Page {
          ...CommonNavigationPageFields
        }
        ... on ExternalLinkNavigationItem {
          ...CommonNavigationExternalLinkFields
        }
      }
    }
  }
`

const getConfig = async () => {
  const res = await client.request(configQuery)
  return res
}

export default {
  getConfig,
}
