const MetaNavigation = ({ config }) => {
  return (
    <nav className="text-center flex justify-center space-x-4">
      {config.navigationItem.map((item, index) => {
        return (
          <a key={index} href={item.url} rel="noopener noreferrer" target="_blank">
            {item.label}
          </a>
        )
      })}
    </nav>
  )
}

export default MetaNavigation
