import React from 'react'

export const ThemeContext = React.createContext({
  interfaceBackgroundColor: {
    hex: '#000000',
  },
  interfaceTextColor: {
    hex: '#FFFFFF',
  },
  contentTextColor: {
    hex: '#ffffff',
  },
  contentDifferenceEffect: false,
})
