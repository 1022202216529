import React, { useEffect, useState } from 'react'
import { render, hydrate } from 'react-dom'
import './styles/index.scss'
import reportWebVitals from './reportWebVitals'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import api from './lib/api'
import Header from './components/Header'
import Page from './components/Page'

import { ThemeContext } from './theme-context'

const App = () => {
  const [config, setConfig] = useState(null)
  const [themeContext, setThemeContext] = useState(ThemeContext._currentValue)

  const insertCookiebotScript = () => {
    const firstDocumentScript = document.getElementsByTagName('script')[0]
    const cookiebotScript = document.createElement('script')
    cookiebotScript.src = 'https://consent.cookiebot.com/uc.js'
    cookiebotScript.id = 'Cookiebot'
    cookiebotScript.type = 'text/javascript'
    cookiebotScript.setAttribute('data-cbid', '56d8e625-cb8c-4abc-ab6f-34058be770ba')
    cookiebotScript.setAttribute('data-blockingmode', 'manual')
    firstDocumentScript.parentNode.insertBefore(cookiebotScript, firstDocumentScript)
  }

  useEffect(() => {
    if (navigator.userAgent !== 'ReactSnap') {
      insertCookiebotScript()
    }

    async function getConfig() {
      const config = await api.getConfig()
      setConfig(config)
    }
    getConfig()
  }, [])

  return (
    <ThemeContext.Provider value={[themeContext, setThemeContext]}>
      <div className="App text-body font-medium">
        {config && (
          <>
            <Header mainNavigation={config.MainNavigation} metaNavigation={config.MetaNavigation} />

            <Routes>
              {config.allPage.map(item => {
                return <Route key={item.slug.current} element={<Page config={item} />} path={item.slug.current} />
              })}
            </Routes>
          </>
        )}
      </div>
    </ThemeContext.Provider>
  )
}

const Wrapper = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  )
}
const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(<Wrapper />, rootElement)
} else {
  render(<Wrapper />, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

//
