import React, { lazy, Suspense, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { ThemeContext } from '../../theme-context'

function Page({ config }) {
  const { cube } = config
  const metaFields = cube.metaFields
  const type = cube.__typename

  /* eslint-disable no-unused-vars */
  const [themeContext, setThemeContext] = useContext(ThemeContext)
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    setThemeContext(cube.themeFields ?? ThemeContext._currentValue)
  })

  useEffect(() => {
    if (metaFields) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          cubeName: metaFields.metaTitle,
        },
      })
    }
  }, [metaFields])

  let GeneratedCube

  switch (type) {
    case 'CubeV1':
      GeneratedCube = lazy(() => import(`../../cubes/CubeV1`))
      break
    case 'CubeV2':
      GeneratedCube = lazy(() => import(`../../cubes/CubeV2`))
      break
    case 'CubeV3':
      GeneratedCube = lazy(() => import(`../../cubes/CubeV3`))
      break

    default:
      break
  }

  return (
    <>
      {metaFields && (
        <Helmet>
          <title>{metaFields.metaTitle}</title>
          <meta content={metaFields.metaDescription} name="description" />

          <meta content="en_US" property="og:locale" />
          <meta content="website" property="og:type" />
          <meta content={metaFields.metaTitle} property="og:title" />
          <meta content={metaFields.metaDescription} property="og:description" />
          <meta content={window.location.href} property="og:url" />
          <meta content="Electronic Beats - The Cube" property="og:site_name" />
          <meta content={metaFields.ogImage.secure_url} property="og:image" />
          <meta content={metaFields.ogImage.secure_url} property="og:image:secure_url" />
          <meta content={metaFields.ogImage.width} property="og:image:width" />
          <meta content={metaFields.ogImage.height} property="og:image:height" />
          <meta content={`${metaFields.ogImage.resource_type}/${metaFields.ogImage.format}`} property="og:image:type" />

          <meta content="summary_large_image" name="twitter:card" />
          <meta content="Electronic Beats - The Cube" name="twitter:title" />
          <meta content={metaFields.metaDescription} name="twitter:description" />
          <meta content={metaFields.ogTwitterHandle} name="twitter:site" />
          <meta content={metaFields.ogImage.secure_url} name="twitter:image" />
          <meta content={metaFields.ogImageAlt} name="twitter:image:alt" />
        </Helmet>
      )}

      {navigator.userAgent !== 'ReactSnap' && (
        <Suspense fallback={null}>
          <GeneratedCube config={cube} />
        </Suspense>
      )}
    </>
  )
}

Page.propTypes = {
  config: PropTypes.object.isRequired,
}

export default Page
