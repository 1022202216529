import { useContext, useState } from 'react'
import MainNavigation from './MainNavigation'
import { ReactComponent as Hexagon } from './../theme/img/hexagon.svg'
import classNames from 'classnames'
import MetaNavigation from './MetaNavigation'

import { ThemeContext } from '../theme-context'

const Logo = ({ onClick, isOverlayOpen, theme }) => {
  const color = isOverlayOpen ? theme.interfaceTextColor.hex : theme.interfaceBackgroundColor.hex
  const useDifferenceEffect = isOverlayOpen ? false : theme.contentDifferenceEffect

  return (
    <button
      className={classNames({
        'fixed z-30 left-4 top-4': true,
        'mix-blend-difference': useDifferenceEffect,
      })}
      onClick={onClick}
    >
      <Hexagon
        className={classNames({
          'cursor-pointer transition-colors w-menu-icon': true,
        })}
        style={{ color }}
      />
    </button>
  )
}

const Header = ({ mainNavigation, metaNavigation }) => {
  const [isOverlayOpen, setOverlayOpen] = useState(false)
  const [themeContext] = useContext(ThemeContext)

  const toggleOverlay = () => {
    setOverlayOpen(!isOverlayOpen)
  }

  return (
    <>
      <Logo isOverlayOpen={isOverlayOpen} theme={themeContext} onClick={toggleOverlay} />

      <div
        className={classNames({
          'fixed z-20 inset-0 w-full h-screen transition-all flex flex-col min-h-screen overflow-y-auto visible': true,
          'opacity-0 invisible': !isOverlayOpen,
        })}
        style={{
          backgroundColor: themeContext.interfaceBackgroundColor.hex,
          color: themeContext.interfaceTextColor.hex,
        }}
      >
        <div>
          <MainNavigation config={mainNavigation} onNavItemClick={toggleOverlay} />
        </div>

        <div className="mt-auto p-4 pt-40">
          <MetaNavigation config={metaNavigation} />
        </div>
      </div>
    </>
  )
}

export default Header
